/**
 * Main.js
 *
 * Dit bestand bevat JS hacks en uitbreidingen. Zo goed als alle code hiervan is dus afkomstig van derden.
 *
 * - crossbrowser console.log
 * - debug() als console.log
 * - getUrlVars
 * - getHashParams
 * - defaultValue
 * - addEvent
 * - findPreviousIndex
 * - orderNumericalArray
 *
 * @todo: controleer of deze functies gebruikt worden, en ruim onnodige code op.
 */

// http://patik.com/blog/complete-cross-browser-console-log/
if(Function.prototype.bind&&(typeof console=="object"||typeof console=="function")&&typeof console.log=="object"){["log","info","warn","error","assert","dir","clear","profile","profileEnd"].forEach(function(method){console[method]=this.call(console[method],console);},Function.prototype.bind);}
if(!window.log){window.log=function(){log.history=log.history||[];log.history.push(arguments);if(typeof console!='undefined'&&typeof console.log=='function'){if(window.opera){var i=0;while(i<arguments.length){console.log("Item "+(i+1)+": "+arguments[i]);i++;}}
else if((Array.prototype.slice.call(arguments)).length==1&&typeof Array.prototype.slice.call(arguments)[0]=='string'){console.log((Array.prototype.slice.call(arguments)).toString());}
else{console.log(Array.prototype.slice.call(arguments));}}
else if(!Function.prototype.bind&&typeof console!='undefined'&&typeof console.log=='object'){Function.prototype.call.call(console.log,console,Array.prototype.slice.call(arguments));}
else{if(!document.getElementById('firebug-lite')){var script=document.createElement('script');script.type="text/javascript";script.id='firebug-lite';script.src='https://getfirebug.com/firebug-lite.js';document.getElementsByTagName('HEAD')[0].appendChild(script);setTimeout(function(){log(Array.prototype.slice.call(arguments));},2000);}
else{setTimeout(function(){log(Array.prototype.slice.call(arguments));},500);}}}}

// aangepaste versie van de debugfunctie van 'TradeMe'  (http://labs.trademe.co.nz/)
// better practice is om gewoon 'log()' te gebruiken
function debug() {console && console.log.apply(console, arguments)}

// functie om de get variabelen uit de url te halen
function getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('#') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}
// functie om de hash-variabelen uit de url te halen
function getHashParams() {
    var hashParams = {};
    var e,
        a = /\+/g,  // Regex for replacing addition symbol with a space
        r = /([^&;=]+)=?([^&;]*)/g,
        d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
        q = window.location.hash.substring(1);
    while (e = r.exec(q))
        hashParams[d(e[1])] = d(e[2]);
    return hashParams;
}
// functie om default values aan te maken (voor in de constructor)
function defaultValue(variable,defaultValue){
    return(typeof variable==='undefined' || variable===null)?(defaultValue):(variable);
}
// Functie om events aan een javascript object te hangen (crossbrowser)
//http://stackoverflow.com/a/3150139/1782659
function addEvent(elem, type, eventHandle) {
    if ( elem.addEventListener ) {
        elem.addEventListener( type, eventHandle, false );
    } else if ( elem.attachEvent ) {
        elem.attachEvent( "on" + type, eventHandle );
    } else {
        elem["on"+type]=eventHandle;
    }
}
/**
 * @param {Float} needle float
 * @param {Array} haystack
 * @returns key of last element smaller than the needle
 */
window.findPreviousIndex = (function(needle, haystack){
    if(undefined === needle || undefined === haystack)
        throw new Error("You have to provide a needle AND a haystack before you can go look for the index.");

    needle = parseFloat(needle);
    if(isNaN(needle) || ! Array.isArray(haystack))
        throw new Error("Needle has to be a numerical value, and the haystack needs to be an Array.");

    var lowPoint = 0, highPoint = haystack.length, middlePoint=0;
    while (lowPoint != highPoint) {
        middlePoint = ((lowPoint + highPoint) / 2) >> 0;
        if (haystack[middlePoint] <= needle) {
            lowPoint = middlePoint + 1.
        } else {
            highPoint = middlePoint;
        }
    }
    if(highPoint > haystack.length)
        highPoint = haystack.length;

    return (highPoint > 0) ? highPoint-1 : 0;
});

/**
 * @param {Array} array
 * @param Boolean isAsc defaults to true, if the ordering should be ascending or descending
 * @returns Array the array ordered based on the nemerical values
 */
window.orderNumericalArray = (function (array, isAsc){
    if(undefined === array)
        throw new Error("You have to provide an array before you can sort it.");
    if(! Array.isArray(array))
        throw new Error("The array variable needs to be of the type 'Array', weird, isn't it?");

    if(undefined === isAsc)
        isAsc = true;

    return array.sort(function(a,b){
        var nA = parseFloat(a),
            nB = parseFloat(b);

        if(isNaN(nA) || isNaN(nA)) throw new Error("The array can only use numerical values.");

        return isAsc ? nA - nB : nB - nA;
    });
});

/**
 * @param {Array} array met numerieke waarden
 * @returns Array the array shuffled
 */
window.shuffleArray = (function(array) {
    var n = array.length;
    while (n--) {
        var i = Math.floor(n * Math.random());
        var tmp = array[i];
        array[i] = array[n];
        array[n] = tmp;
    }
    return array;
});


/**
 * @param {Array} array met numerieke waarden
 * @returns Minimum waarde van de array
 */
function arrayMin(arr) {
    var len = arr.length, min = Infinity;
    while (len--) {
        if (Number(arr[len]) < min) {
            min = Number(arr[len]);
        }
    }
    return min;
};

/**
 * @param {Array} array met numerieke waarden
 * @returns Maximum waarde van de array
 */
function arrayMax(arr) {
    var len = arr.length, max = -Infinity;
    while (len--) {
        if (Number(arr[len]) > max) {
            max = Number(arr[len]);
        }
    }
    return max;
};

/**
 * @function touchToMouse
 * @description translates TouchEvents into MouseEvents
 * @param {Event} evt
 * @param {Enyo.control} originator
 */
window.touchToMouse = function(evt, originator) {
    /** @type {{touchstart: string, touchmove: string, touchend: string, touchcancel: string}} */
    var eventTypes = {
        touchstart: "mousedown",
        touchmove: "mousemove",
        touchend: "mouseup",
        touchcancel: "abort"
    };

    /** @type {int} */
    var i = evt.changedTouches.length - 1;

    for (i; i>=0; i--) {
        /** @type {Touch} */
        var touch = evt.changedTouches[i];

        /** @type {String} */
        var eventType = eventTypes[evt.type];

        /** @type {{screenX: number, screenY: number, clientX: number, clientY: number, ctrlKey: *, altKey: *, shiftKey: *, metaKey: *, relatedTarget: null}} */
        var eventInit = {
            screenX: touch.screenX,
            screenY: touch.screenY,
            clientX: touch.clientX,
            clientY: touch.clientY,
            ctrlKey: touch.ctrlKey,
            altKey: touch.altKey,
            shiftKey: touch.shiftKey,
            metaKey: touch.metaKey,
            relatedTarget: null
        };

        /** @type {MouseEvent} */
        var simulatedEvent = new MouseEvent(eventType, eventInit);

        var element = enyo.$[this.id];
        if (typeof element === 'undefined') {
            this.dispatchEvent(simulatedEvent)
        } else {
            if(typeof originator !== 'undefined') {
                simulatedEvent.originator = originator;
            } else if(typeof enyo.$[touch.target.id] !== 'undefined') {
                simulatedEvent.originator = enyo.$[touch.target.id];
            }

            element.bubble('on' + eventType, simulatedEvent, element);
        }
    }


    evt.preventDefault();
};