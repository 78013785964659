
var ulIndent = 25;          //in pixels
var slideSpeed = '250';   // 'slow', 'normal', 'fast', or miliseconds

$(function() {
    $.fn.mlamenu = function() {
        /*
         open/close current each list on click
         */
        $('.navcon p').click(function(e) {
            e.preventDefault();
            $(this).parent('div').children('div').hide(slideSpeed);
            $(this).delay(100).is(':hidden');
            if ($(this).parent('div').children('div').css('display') == "block") {
                $(this).parent('div').children('div').hide(slideSpeed);
            } else {
                $(this).parent('div').children('div').show(slideSpeed);
            }
            return false;
        });
    }
});

function closeAll() {
    $('.navcon div').children('div').each(function () {
        $(this).hide(slideSpeed);
    });
}